
@media only screen and (max-width: 760px) {
    .rc {
        border-radius: 0 0 50px 50px;
    }
    .tos {
        flex: 1 !important;
        width: 100% !important;
    }
    .tosc {
        display: flex !important;
        flex-direction: column !important;
        height: 100vh;
    }

    
}